<template>
    <div class="mainSpyContainer">
        <Duzen></Duzen>
    </div>
</template>

<script>
    import Duzen from './Duzen';


    export default {
        components: {
            Duzen
        }
    }
</script>



